<template>
  <div class="settings__import-export">
    <div class="tiny__content">
      <!-- HIDDEN FILE INPUT -->
      <input type="file" class="hidden" ref="fileInput" @change="fileSelected" />
      <template v-if="activeCompany === 'КЦМ АД'">
        <p class="tiny__content__title">РЪЧЕН КОНТРОЛ ВЪРХУ БАЗАТА ДАННИ</p>
        <hr class="line" />
        <div class="row">
          <p>
            <span class="icon">m</span>
            Импортиране на нова база-данни в CSV формат
          </p>
          <Button class="button--green" @click="toggleImport()">Импортирай</Button>
        </div>
      </template>
      <p class="tiny__content__title">ЕКСПОРТ НА ОТПУСКИТЕ</p>
      <hr class="line" />
      <div class="row">
        <p>
          <span class="icon">n</span>
        </p>
        <div class="dates">
          <span class="date-toggle" @click="selectDate = 'dateFrom'">
            <strong>От </strong>
            <span v-if="dateFrom">{{ dateFrom | formatDate }}</span>
            <span v-else>Избери</span>
          </span>
          <span class="date-toggle" @click="selectDate = 'dateTo'">
            <strong>До </strong>
            <span v-if="dateTo">{{ dateTo | formatDate }}</span>
            <span v-else>Избери</span>
          </span>
        </div>
        <Button class="button--green" @click="getExport()">Експортирай</Button>
      </div>
      <p class="tiny__content__title">ЕКСПОРТ НА ПОЧАСОВИТЕ ОТПУСКИ</p>
      <hr class="line" />
      <div class="row">
        <p>
          <span class="icon">n</span>
        </p>
        <div class="dates">
          <span class="date-toggle" @click="selectDate = 'dateFromHourly'">
            <strong>От </strong>
            <span v-if="dateFromHourly">{{ dateFromHourly | formatDate }}</span>
            <span v-else>Избери</span>
          </span>
          <span class="date-toggle" @click="selectDate = 'dateToHourly'">
            <strong>До </strong>
            <span v-if="dateToHourly">{{ dateToHourly | formatDate }}</span>
            <span v-else>Избери</span>
          </span>
        </div>
        <Button class="button--green" @click="getExportHourly()">Експортирай</Button>
      </div>
      <!-- <div class="row">
        <p>
          <span class="icon">n</span>
          Експорт на отпуските
        </p>
        <Button class="button--green" @click="selectDate = true"
          >Експортирай</Button
        >
      </div> -->
    </div>
    <transition name="fade">
      <DatepickerModal v-if="selectDate" :value="this[selectDate]" @selected="dateSelected" @close="selectDate = ''" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DatepickerModal from '@/components/inputs/DatepickerModal';
import Button from '@/components/Button';
export default {
  components: {
    DatepickerModal,
    Button,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  data() {
    return {
      selectDate: false,
      files: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      dateFromHourly: new Date(),
      dateToHourly: new Date(),
    };
  },
  methods: {
    dateSelected(d) {
      this[this.selectDate] = d;
      this.selectDate = '';
    },
    getExport() {
      window.open(
        `${process.env.VUE_APP_BASE_URI}/exports/vacations/${this.dateFrom.toISOString()}/${this.dateTo.toISOString()}`
      );
    },
    getExportHourly() {
      window.open(
        `${
          process.env.VUE_APP_BASE_URI
        }/exports/vacations-hourly/${this.dateFromHourly.toISOString()}/${this.dateToHourly.toISOString()}/${
          this.activeCompany
        }`
      );
    },
    fileSelected(e) {
      if (e.target.files.length) {
        if (e.target.files[0].name.match(`.(csv|CSV)$`)) {
          const fd = new FormData();
          fd.append('file', e.target.files[0]);
          this.$apiService.post('/imports/uservacations', fd).then(() => {
            // if (!this.currentUser.hidden) {
            //   this.$store.dispatch('logout').then(() => {
            //     if (this.$route.path !== '/') {
            //       this.$router.push('/');
            //     }
            //   });
            // }
            this.$store.dispatch('startImporting');
          });
        } else {
          this.$snotify.error('Може да качвате само .csv файлове');
        }
      }
    },
    toggleImport() {
      const msg = `Сигурни ли сте, че искате да качите нов файл? След като изберете файла, той ще бъде качен автоматично. Ситемата временно ще спре да работи и всички потребителски профили ще бъдат автоматично деактивирани, докато не завърши процесът по актуализиране на данните.`;
      if (confirm(msg)) {
        this.$refs.fileInput.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.hidden {
  height: 1px;
  left: -100vw;
  opacity: 0;
  position: absolute;
  top: -100vh;
  width: 1px;
  z-index: -9999;
}
.row {
  align-items: center;
  background-color: $mainColor;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 11px 0 0;
  padding: 20px 20px 20px 10px;
  p {
    align-items: center;
    display: flex;
    > .icon {
      font-size: 18px;
      margin-right: 0.5rem;
    }
  }
  .date-toggle {
    @include hover();
    &:last-child {
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .row {
    padding: 20px;
    flex-wrap: wrap;
    .button {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
</style>
